import React, { Component } from 'react'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'

import './nav-sub-cat.scss'

class NavigationSubCategory extends Component {
    render() {
        // console.log('Data Tabs Subcategory :', this.props)
        // console.log('Data Tabs Aktif :', this.props.active)
        return (
            <div>
                <div className="menu-subcat">
                    <Navbar bg="none" expand="md" className="d-md-block d-none">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav ">
                            <Nav className="navigation-box mont-regular">
                                {this.props.data.map((item) => {
                                    if (item.slug === 'hair-mask-1') {
                                        return (
                                            <Nav.Link
                                                className={
                                                    !this.props.active
                                                        ? ``
                                                        : this.props.active
                                                              .slug ===
                                                          item.slug
                                                        ? `active`
                                                        : ``
                                                }
                                                key={item._id}
                                                onClick={() =>
                                                    this.props.handleChangeSub(
                                                        item._id,
                                                        this.props.active
                                                    )
                                                }
                                            >
                                                Vitamin Hair Mask
                                            </Nav.Link>
                                        )
                                    } else if (item.slug === 'hair-mask-2') {
                                        return (
                                            <Nav.Link
                                                className={
                                                    !this.props.active
                                                        ? ``
                                                        : this.props.active
                                                              .slug ===
                                                          item.slug
                                                        ? `active`
                                                        : ``
                                                }
                                                key={item._id}
                                                onClick={() =>
                                                    this.props.handleChangeSub(
                                                        item._id,
                                                        this.props.active
                                                    )
                                                }
                                            >
                                                Pro Keratin Complex
                                            </Nav.Link>
                                        )
                                    } else {
                                        return (
                                            <Nav.Link
                                                className={
                                                    !this.props.active
                                                        ? ``
                                                        : this.props.active
                                                              .slug ===
                                                          item.slug
                                                        ? `active`
                                                        : ``
                                                }
                                                key={item._id}
                                                onClick={() =>
                                                    this.props.handleChangeSub(
                                                        item._id,
                                                        this.props.active
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </Nav.Link>
                                        )
                                    }
                                })}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    {this.props.active && (
                        <Dropdown className="d-md-none d-block">
                            <div>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="subcat-list btn-primary__fill-pink"
                                >
                                    {this.props.active
                                        ? this.props.active.name
                                        : ''}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.props.data.map((item) => {
                                        return (
                                            <Dropdown.Item
                                                onClick={() =>
                                                    this.props.handleChangeSub(
                                                        item._id,
                                                        this.props.active
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </div>
                        </Dropdown>
                    )}
                </div>
            </div>
        )
    }
}

export default NavigationSubCategory